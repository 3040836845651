import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'

import './App.css';
import { BackGround } from './components/styles';
import { Main } from './components/main';
import { Nav } from './components/nav';
import { createBrowserRouter,
  RouterProvider, BrowserRouter
  } from "react-router-dom";
import { AddRound } from './components/addRound';
import { EditModal } from './components/editModal';
import { useState } from 'react';

const queryClient = new QueryClient()

function App() {
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedRound, setRound] = useState()

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main setModalOpen={setModalOpen} setRound={setRound} />,
    },
    {
      path: "/addRound",
      element: <AddRound />,
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
    <BackGround>
      <Nav />
      <RouterProvider router={router} />
     { modalOpen &&  <EditModal setModalOpen={setModalOpen} selectedRound={selectedRound} /> }
    </BackGround>
    </QueryClientProvider>
   
  );
}

export default App;
