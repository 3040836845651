import { useEffect, useState } from 'react'
import { addArray } from '../utils/math'
import { Content, CourseCell, CourseCellTitle, CourseMain, EditTool, FormLine, Title } from './styles'
import {
    useQuery,
  } from '@tanstack/react-query'
  import axios from 'axios'
import { BASE_API } from '../utils/constants'
  
const COLUMNS = new Array(20).fill(0)

const f = [0,1,2,3,4,5,6,7,8]
const l = [9,10,11,12,13,14,15,16,17]

export const Main = ({ setModalOpen, setRound }) => {
    // const rounds = localStorage.getItem('rounds')
    const [rounds, setRounds] = useState();
    // const savedCourses = JSON.parse(localStorage.getItem('courses'))
    const [savedCourses, setSavedCourses ] = useState([{}])

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
    }, []);

    const isMobile = width <= 768;

    const { isLoading: courseLoading, error: courseError, data: courseData } = useQuery({
        queryKey: ['courses'],
        queryFn: () =>
            axios.get(BASE_API + '/getcourses')
      }) 

    const { isLoading, error, data } = useQuery({
        queryKey: ['rounds'],
        queryFn: () =>
            axios.get(BASE_API + '/getrounds')
      })

      useEffect(() => {
        if (courseData && !courseLoading) {
            setSavedCourses(courseData.data.result)
        }
      }, [courseData])

      useEffect(() => {
        if (data) {
            setRounds(data.data.result)
        }
      }, [data])

    if (isLoading || courseLoading || !rounds) return <div>Loading...</div>

    if (error || courseError) return <div>An error has occurred: {error.message}</div>

    const createRoundTable = (rds) => {
        if (!rounds) return
        const courses = {}
        rounds.forEach(r => {
            if (!r.name) return
           const { name, tee} = r
            const obj = {
                score: r.score,
                date: r.date
            }
            if (!courses[name]) {
                courses[name] = {
                    [tee]: [obj]
                }
            } else if (courses[name] && !courses[name][tee]) {
                courses[name][tee] = [obj]
            
            } else if (courses[name] && courses[name][tee]) {
                courses[name][tee] = [...courses[name][tee], obj]
            }
        })
       // return 
       return Object.keys(courses).map((course) => {
        const saved = savedCourses.find(c => course === c.name) || {}
        const tees = Object.keys(saved.tees || {})
        if (!saved.pars) return <div />
        return (
            <CourseMain>
                <Title>{course}</Title>
                <FormLine>
                    <CourseCellTitle>Hole:</CourseCellTitle>
                    {COLUMNS.map((t, i) => {

                        if (i === 9) return  <CourseCell key={`hole${t}-${i}`}>OUT</CourseCell>
                        if (i === 19) return  <CourseCell key={`hole${t}-${i}`}>IN</CourseCell>
                        const val = i > 9 ? i : i + 1
                        return <CourseCell key={`hole${t}-${i}`}>{val}</CourseCell>
                    })}
                    <CourseCell>Totals:</CourseCell>
                </FormLine>
                <FormLine>
                    <CourseCellTitle>Pars:</CourseCellTitle>
                    {/* { saved.pars?.map((t, i) => <CourseCell key={`pars${t}-${i}`}>{t}</CourseCell>)} */}
                    {COLUMNS.map((t, i) => {
                        if (i === 9) return  <CourseCell key={`hole${t}-${i}`}>{addArray(saved.pars) / 2}</CourseCell>
                        if (i === 19) return  <CourseCell key={`hole${t}-${i}`}>{addArray(saved.pars) / 2}</CourseCell>
                        const val = i > 9 ? saved.pars?.[i - 1] : saved.pars?.[i] || 0
                        return <CourseCell key={`hole${t}-${i}`}>{val}</CourseCell>
                    })}
                    
                    <CourseCell key={`parstotal`}>{addArray(saved.pars)}</CourseCell>
                </FormLine>
                {
                    tees.map(tee => {
                        if (courses[course]?.[tee]) {
                           return  (
                            <>
                           <FormLine>
                                <CourseCellTitle>{tee}</CourseCellTitle>
                                {/* {saved.tees?.[tee]?.map((t, i) => <CourseCell key={`tolor${t}-${i}`}>{t}</CourseCell>)} */}
                               {COLUMNS.map((w,i) => {
                                let t = saved.tees[tee][i]
     
                                const fpars = f.map((ii) => saved.tees?.[tee][ii])
                                const lpars = l.map((iii) => saved.tees?.[tee][iii])
                                if (i > 9) t = saved.tees?.[tee][i - 1]
                                if (i === 9) t = addArray(fpars)
                                if (i === 19) t = addArray(lpars)

                                return <CourseCell key={`tolor${t}-${i}`}>{t}</CourseCell>
                               })}
                            
                                <CourseCell>{addArray(saved.tees?.[tee] || [0])}</CourseCell>
                            </FormLine>
                                {
                                courses[course]?.[tee].sort((a,b) => {
                                    return new Date(a.date) - new Date(b.date) 
                                }).map(score => {
                                    return (
                                        <FormLine className={'score'}>
                                        <CourseCellTitle>{score.date}
                                        <EditTool onClick={() => 
                                            { setModalOpen(true); setRound({course, score})}
                                        }>x</EditTool></CourseCellTitle>
                                        {COLUMNS.map((w, i) => { 
                                            let t = score.score[i]

                                            const fpars = f.map((ii) => score.score[ii])
                                            const lpars = l.map((iii) => score.score[iii])
                                            if (i > 9) t = score.score[i - 1]
                                            if (i === 9) t = addArray(fpars)
                                            if (i === 19) t = addArray(lpars)

                                            const diff = i < 9 ? t - saved.pars[i] : t - saved.pars[i - 1]
                                            let classes = diff < -1 ? 'eagle' : diff > 1 ? 'dbogey' : diff === -1 ? 'birdy' : diff === 1 ? 'bogey' : '' 
                                            if (i === 9 || i > 18 || t === 0) classes = ''
                                            t = t === 0 ? '-' : t

                                            return (
                                                <CourseCell key={`score${t}-${i}`} >
                                                <span className={`score-number ${classes}`}>{t}</span>
                                            </CourseCell>
                                            )})}
                                            <CourseCell>
                                                {addArray(score.score)}
                                            </CourseCell>
                                    </FormLine> 
                                    )
                                })
                        }
                            </>
                           )
                        }
                    })
                }
            </CourseMain>
        )
        
       })

    } 

    const mobileReturn = () => {
        return (
        <div>
            Mobile
        </div>
        )
    }

    if (!rounds) return <div /> 

    return (
        <Content>
            {
                // isMobile ? mobileReturn() :
            
          createRoundTable(rounds) 
          }
        </Content>
    )
}