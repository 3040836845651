// import { Link } from "react-router-dom"
import { Actions, Logo, NavLink, Navbar } from "./styles"



export const Nav = () => {
    return (
    <Navbar>
        <Logo>
            <span>Golf App</span>
        </Logo>
        <Actions>
            <li><NavLink href={'/'}>all rounds</NavLink></li>
            <li><NavLink href={'addRound'}>Add new Round</NavLink></li>
        </Actions>
    </Navbar>
    )

}