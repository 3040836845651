import styled from 'styled-components'

export const BackGround = styled.div`
    background: darkgreen;
    min-height: 100vh;
    font-family: 'Abel', sans-serif;
`

export const Actions = styled.div`
    display: flex;
    font-size: 25px;
    margin: auto;
    li {
        margin-left: 4rem;
        list-style: none;
        color: wheat;
        cursor: pointer;
    }
    @media (max-width: 460px) {
    font-size: 16px;
    display: inline-grid;
    float: inline-end;
    }
`

export const Content = styled.div`
   padding: 4rem;
    color: white;
    font-size: 24px;

    @media  (max-width: 460px) {
        padding: 0px;
    }
    
`
export const Navbar = styled.div`
display: flex;
min-height: 7rem;
line-height: 2rem;
padding: 12px;
border-bottom: 1px solid lightgreen;

@media (max-width: 460px) {
    display: block;
}
`
export const Logo = styled.div`
position: relative;
background:url(http://thumbs.dreamstime.com/x/golf-ball-texture-17926947.jpg) 0% 0 repeat-x;
width:150px;
height:150px;
position:absolute;
border-radius:50%;
text-align: center;

span {
    font-family: 'Dancing Script', cursive;
    position: relative;
    top: 51px;
    font-size: 46px;
}

`

export const NavLink = styled.a`
    color: wheat;
    text-decoration: none;
`

export const GolfLabel = styled.label`
    display: inline-block;
    padding-right: 2rem;
`

export const RoundFormWrapper = styled.div`
    margin: 3rem;
    color: white;
    font-size: 20px;
    p {
        display: flex;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }

    @media (max-width: 460px) {
        margin: 2px;
        font-size: 8px;
    }
    
` 

export const CourseCell = styled.div`
    width: 5rem;
    height: 1.5rem;
    padding: 8px;
    text-align: center;

    input {
        width: 1rem;
        height: 1rem;
    }

    @media (max-width: 460px) {
        height: 18px;
        padding: 1px;
        width: 20px;
    }
`

export const CourseCellTitle = styled(CourseCell)`
    width: 16rem;
    text-align: left;
    min-width: 7rem;
    input {
        width: 7rem;
        height: 1rem;
    }

    @media (max-width: 460px) {
        width: 0px;
        min-width: 38px;
    }
`

export const TeeColor = styled.div`
    height: 20px;
    width: 20px;
    margin-left: 16px;
    border-radius: 50%;
    display: inline-block;
    background-color: ${props => props.bg};

`

export const FormLine = styled.div`
display: flex;
margin-block-start: 0px;
margin-block-end: 0px;
`

export const BottomLine = styled.div`
display: grid;
    grid-template-columns: 11% 34% 15%;
    height: 3rem;
`

export const CourseMain = styled.div`
    margin: 1rem;
    min-width: 90vw;
    min-height: 10rem;
    background: lightgrey;
    border: 1px solid grey;
    border-radius: 10px;
    font-size: 14px;
    color: darkslategrey;

    @media (max-width: 460px) {
        font-size: 6px;
    }

`
export const Title = styled.div`
    width: max-content;
    margin: auto;
    color: #1c0505;
    font-size: 2rem;
`

export const EditTool = styled.span`
    display: inline-block;
    height: 1rem;
    width: 1rem;
    margin-left: 12px;
    opacity: 0;
    cursor: pointer;
    padding: 3px;

    &:hover {
        color: red;
        opacity: 1;
    }
`

export const ModalScreen = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    background: grey;
    opacity: 0.75;
    width: 100%;
    height: 100rem;
    z-index: 100;
`

export const Modal = styled.div`
width: 90%;
    height: 20rem;
    background: white;
    margin: 5rem auto;
    border-radius: 10px;
    position: absolute;
    z-index: 300;
    left: 5%;
    top: 10rem;
`
