import { useEffect, useState } from "react"
import { Content, CourseCell, CourseCellTitle, FormLine, GolfLabel, RoundFormWrapper, TeeColor, BottomLine } from "./styles"
import { addArray } from "../utils/math"
import { useNavigate } from 'react-router'
import axios from "axios"
import {
    useQuery,
  } from '@tanstack/react-query'
import { BASE_API } from "../utils/constants"

const saveRoundApi = async (payload) => {
    try {
        const res = await axios.post(BASE_API + '/submitround', payload)
        console.log('success', res)
        return res.data
    } 
    catch(err) {
        console.log('error occured', err)
        return err
    }
}

const saveCourseApi = async (payload) => {
    try {
        const res = await axios.post(BASE_API +  '/submitcourse', payload)
        console.log('success', res)
        return res.data
    } 
    catch(err) {
        console.log('error occured', err)
        return err
    }
}



// const COURSES = [
//     {
//         name: 'Honey Bee Golf, VA Beach',
//         tees: {
//             'gold': [511,170,338,178,304,373,524,160,379,461,179,341,190,361,435,197,400,495],
//             'black': [475,159,321,162,286,359,494,145,348,443,161,319,173,343,415,166,368,472],
//             'blue':  [459,146,299,138,276,340,455,128,329,374,132,299,121,322,394,143,328,465],
//             'silver':[400,128,296,130,262,326,442,123,325,367,128,294,111,299,384,138,325,456],
//             'red':   [350,110,246,111,250,275,420,110,265,333,110,240,90 ,254,329,96 ,215,366]
//         },
//         pars: [5,3,4,3,4,4,5,3,4, 5,3,4,3,4,4,3,4,5]
//     }

// ]

export const AddRound = () => {
    const [course, setCourse] = useState('')
    const [score, setScore] = useState(Array(18).fill(0))
    const [date, setDate] = useState('')
    const navigate = useNavigate()
    const [courses, setCourses ] = useState()


    const { isLoading: courseLoading, error: courseError, data: courseData } = useQuery({
        queryKey: ['courses'],
        queryFn: () =>
            axios.get(BASE_API + '/getcourses')
      }) 


      useEffect(() => {
        if (courseData && !courseLoading) {
            setCourses(courseData.data.result)
        }
      }, [courseData])
    
      if (courseLoading || !courses) return <div>Loading...</div>

      if (courseError) return <div>An error has occurred: {courseError.message}</div>
    
    // const savedCourses = localStorage.getItem('courses')
    // if (!savedCourses) localStorage.setItem('courses', JSON.stringify(COURSES))
    // console.log(savedCourses)
    // const allCourses = savedCourses ? [...COURSES, ...JSON.parse(savedCourses)] : COURSES

    const saveRound = () => {
        const [name, tee] = course.split(' - ')
        const round = {
            date,
            score,
            course,
            name,
            tee
        }
        // const res = localStorage.getItem('rounds')
        // const rounds = res ? JSON.parse(res) : []
        // rounds.push(round)
        // const rds = JSON.stringify(rounds)
        // localStorage.setItem('rounds', rds)
        // saveToMongo(rds)
        // navigate('/')
        saveRoundApi(round)
        

    }
    const formatDate = () => {
        if (date.length) {
            const d = date.split('-')
            return `${d[1]}-${d[2]}-${d[0]}`
        }
        return null

    }
    const renderForm = () => {
        const [name, color] = course.split(' - ')
        const c = courses.find((cc) => cc.name === name)
        const tee = c.tees[color]
        return (
            <RoundFormWrapper>
                <FormLine>{name} <TeeColor bg={color} /></FormLine>
                <FormLine>
                    <CourseCellTitle>Hole:</CourseCellTitle>{tee.map((t, i) => <CourseCell key={`hole${t}-${i}`}>{i + 1}</CourseCell>)}
                </FormLine>
                <FormLine>
                    <CourseCellTitle>Yardage:</CourseCellTitle>{tee.map((t, i) => <CourseCell key={`tee${t}-${i}`}>{t}</CourseCell>)}
                </FormLine>
                <FormLine>
                    <CourseCellTitle>Par:</CourseCellTitle>{c.pars.map((t, i) => <CourseCell key={`par${t}-${i}`}>{t}</CourseCell>)}
                </FormLine>
                <FormLine>
                    <CourseCellTitle>Your score:</CourseCellTitle>{score.map((s, i) => 
                    <CourseCell key={`${s}--${i}`}>
                        {/* <input value={s} onChange={(e) => {
                        const newScore = [...score]
                        newScore[i] = e.target.value
                        setScore(newScore)
                    }}  /> */}
                    <select 
                        key={`select${s}${i}`}
                        id={`optionSelect${s}${i}`} onChange={(e) => {
                            const newScore = [...score]
                            newScore[i] = e.target.value
                            setScore(newScore)
                        }}
                        value={s}
                    >
                    {
                        Array(8).fill(0).map((_num, ii) => {
                            return (
                                <option key={`${s}${i}${ii}row`} value={ii + 1}>{ii + 1}</option>
                            )
                        })
                    } 
                    </select>
                    </CourseCell>)}
                </FormLine>
                <BottomLine>
                    <CourseCellTitle>Date: {formatDate()}</CourseCellTitle>
                    <CourseCellTitle><input type='date' value={date} onChange={(e) => setDate(e.target.value)} /></CourseCellTitle>
                    <CourseCellTitle><button 
                    onClick={()=>saveRound()}
                    disabled={date.length < 5 || addArray(score) < 27 }
                    >Save Round</button></CourseCellTitle>
                </BottomLine>

            </RoundFormWrapper>
        )

    }

    const onClick = (e) => {
        if (e === 'createnew') console.log('do something')
    }

    console.log('sel', score, date, addArray(score), courses)
    return (
        <Content>
            <p>Adding round</p>
            <GolfLabel htmlFor ='courses'>Select Existing course</GolfLabel>
            <select onClick={(e) => onClick(e.target.value)} value={course} onChange={(c) => setCourse(c.target.value)} name='courses' id='courses'>
                <option  key={'none'} value={''}>{'Select Course'}</option>
                <option  key={'new'} value={'createnew'}>{'Add New Course'}</option>
                {courses.map((course) => {
                    const tees = Object.keys(course.tees)
                    return tees.map((tee, index) => {
                        const name = `${course.name} - ${tee}`
                        return <option key={name + index} value={name}>{name}</option>
                    })
                })}
            </select>

            {
                course.length > 1 && course !== 'createnew' && renderForm()
            }

            

        {/* <button onClick={() => saveCourseApi(COURSES[0])}>save course</button>   */}



        </Content>
    )
}