import { CourseCell, CourseCellTitle, FormLine, Modal, ModalScreen } from "./styles"


export const EditModal = ({setModalOpen, selectedRound}) => {

    const { score, date } = selectedRound.score

    const remove = () => {
        if (window.confirm('Are you sure you want this deleted?')) {
            const res = JSON.parse(localStorage.getItem('rounds'))
            if (!res) return;
            const next = res.filter((rds) => {
                console.log(rds, selectedRound)
                return !(rds.name === selectedRound.course && rds.date === selectedRound.score.date)
            })
            console.log('next: ', next)
            localStorage.setItem('rounds', JSON.stringify(next))
            window.location.reload();
        }
    }

    return (
        <>
        <ModalScreen onClick={() => setModalOpen(false)} />
        <Modal>
            <h3>Edit or remove Round from {selectedRound.course}</h3>
            {
                <FormLine className={'score'}>
                <CourseCellTitle>{date}</CourseCellTitle>{score?.map((t, i) => { 
                    return (
                        <CourseCell key={`score${t}-${i}`} >
                        <span className={`score-number`}>{t}</span>
                    </CourseCell>
                    )})}
            </FormLine> 
            }
            <button onClick={() => remove()} >Remove round?</button>

        </Modal>
        </>

    )
}