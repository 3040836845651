// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.score {
  font-weight: bold;
  font-size: 16px;
}



.score-number {
  width: 20px;
  height: 20px;
  
  display: inline-block;
}

.bogey {
  border: 1px solid grey;
  padding: 2px;
}

.dbogey {
  border: 3px double grey;
}

.birdy {
  border: 1px solid grey;
  border-radius: 50%;
  padding: 2px;
}

.eagle {
  border: 3px double grey;
  border-radius: 50%;
}

@media (max-width: 460px) {
  .score {
    font-weight: 100;
    font-size: 8px;
  }
  .score-number {
    width: auto;
    height: auto;
  }



  select {
    font-size: 8px;
    width: 11px;
    -webkit-appearance: none;
    background: #fff;
    -moz-appearance: none;
    color: darkgrey;


  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;;;AAIA;EACE,WAAW;EACX,YAAY;;EAEZ,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE;IACE,gBAAgB;IAChB,cAAc;EAChB;EACA;IACE,WAAW;IACX,YAAY;EACd;;;;EAIA;IACE,cAAc;IACd,WAAW;IACX,wBAAwB;IACxB,gBAAgB;IAChB,qBAAqB;IACrB,eAAe;;;EAGjB;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n\n.score {\n  font-weight: bold;\n  font-size: 16px;\n}\n\n\n\n.score-number {\n  width: 20px;\n  height: 20px;\n  \n  display: inline-block;\n}\n\n.bogey {\n  border: 1px solid grey;\n  padding: 2px;\n}\n\n.dbogey {\n  border: 3px double grey;\n}\n\n.birdy {\n  border: 1px solid grey;\n  border-radius: 50%;\n  padding: 2px;\n}\n\n.eagle {\n  border: 3px double grey;\n  border-radius: 50%;\n}\n\n@media (max-width: 460px) {\n  .score {\n    font-weight: 100;\n    font-size: 8px;\n  }\n  .score-number {\n    width: auto;\n    height: auto;\n  }\n\n\n\n  select {\n    font-size: 8px;\n    width: 11px;\n    -webkit-appearance: none;\n    background: #fff;\n    -moz-appearance: none;\n    color: darkgrey;\n\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
